export type WLCOLORSTYPES =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "warning"
  | "danger"
  | "dark"
  | "medium"
  | "light"
  | "disabled"
  | "white";

interface WLColors {
  [colorKey: string]: WLCOLORSTYPES;
}

export const WLCOLORS = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  success: "success",
  warning: "warning",
  danger: "danger",
  dark: "dark",
  medium: "medium",
  light: "light",
  disabled: "disabled",
  white: "white",
} satisfies WLColors;

export type KlaviyoProductEvents =
  | "StartCheckout"
  | "CompletePurchase"
  | "CancelSubscription"
  | "DeleteAccount"
  | "PauseSubscription"
  | "UnpauseSubscription"
  | "ClaimDiscountInsteadCancel"
  | "ClaimDowngradeInsteadCancel"
  | "ClaimedFreePowerUpInsteadCancel";
export type KlaviyoPurchaseTypes =
  | "subscription"
  | "upgrade"
  | "downgrade"
  | "powerup";
