import axios, { AxiosRequestHeaders } from "axios";
import { CapacitorHttp, HttpOptions } from "@capacitor/core";

import * as capacitorStorage from "./localStorage";
import { LOCAL_STORAGE } from "../config";
import { isMobile } from "./common";

export type WLCommonPromiseReturn = {
  data: string;
};
type WLHeaders = HttpOptions["headers"] | AxiosRequestHeaders;
type WLBody = {
  [key: string]: any;
};
const HTTP_STATUS = {
  ok: 200,
  created: 201,
  noContent: 204, // Often used for successful PUT or DELETE operations
  badRequest: 400, // Bad request error
  unauthorized: 401, // Authentication has not yet been provided or is failed
  forbidden: 403, // No Permission
  notFound: 404, // Resource not found
  internalServerError: 500, // Generic error message, a server-side error
};

export const getRequestHeaders = async (authenticated = false) => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (authenticated) {
    const id_token =
      (await capacitorStorage.getItem(LOCAL_STORAGE.fbIdToken)) || "";
    const user_id =
      (await capacitorStorage.getItem(LOCAL_STORAGE.userId)) || "";
    headers = {
      ...headers,
      ...{ id_token, user_id },
    };
  }
  return headers;
};

export const encodeQueryData = (data: any) => {
  return Object.keys(data)
    .map(function (key: any) {
      const value = data[key];
      // Only encode if not on mobile platform
      const encodedKey = isMobile() ? key : encodeURIComponent(key);
      const encodedValue = isMobile() ? value : encodeURIComponent(value);
      return `${encodedKey}=${encodedValue}`;
    })
    .join("&");
};

const handleResponse = (response: any) => {
  console.log("response --- ", JSON.stringify(response, null, 1));
  if (
    [
      HTTP_STATUS.noContent,
      HTTP_STATUS.badRequest,
      HTTP_STATUS.unauthorized,
      HTTP_STATUS.forbidden,
      HTTP_STATUS.notFound,
      HTTP_STATUS.internalServerError,
    ].indexOf(response.status) !== -1
  ) {
    throw new Error(response.data);
  } else return response;
};

export const doGet = (httpUrl: string, headers: WLHeaders) => {
  return isMobile()
    ? CapacitorHttp.get({ url: encodeURI(httpUrl), headers }).then(
        (response) => {
          return handleResponse(response);
        }
      )
    : axios.get(httpUrl, {
        headers: headers,
      });
};

export const doPost = (httpUrl: string, headers: WLHeaders, data: WLBody) => {
  return isMobile()
    ? CapacitorHttp.post({
        url: encodeURI(httpUrl),
        headers,
        data,
      }).then((response) => {
        return handleResponse(response);
      })
    : axios.post(httpUrl, data, {
        headers: headers,
      });
};

export const doPatch = (httpUrl: string, headers: WLHeaders, data: WLBody) => {
  return isMobile()
    ? CapacitorHttp.patch({
        url: encodeURI(httpUrl),
        headers,
        data,
      }).then((response) => {
        return handleResponse(response);
      })
    : axios.patch(httpUrl, data, {
        headers: headers,
      });
};

export const doDelete = (httpUrl: string, headers: WLHeaders) => {
  return isMobile()
    ? CapacitorHttp.delete({
        url: encodeURI(httpUrl),
        headers,
      }).then((response) => {
        return handleResponse(response);
      })
    : axios.delete(httpUrl, {
        headers: headers,
      });
};
